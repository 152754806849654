import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My principal <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently. 
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="NodeJS Contributor"
              description="SA1-D active contributor to the NodeJS project and its derivatives."
              ghLink="https://github.com/nodejs/node"
              demoLink="https://nodejs.org/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="LiDAR Scanner"
              description="Collaborator of the LiDAR Scanner project in its integration to Apple X series mobile devices.
              AR engine designer at Andromeda Studios (Development studio affiliated with Apple Inc.)."
              ghLink="https://github.com/SHManuel"
              demoLink="https://www.apple.com/newsroom/2020/03/apple-unveils-new-ipad-pro-with-lidar-scanner-and-trackpad-support-in-ipados/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="CBank-of-America"
              description="Designer and auditor of Bank of America's financial service accounting and management system."
              ghLink="https://github.com/SHManuel"
              demoLink="https://github.com/SHManuel"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="Ai-SDS"
              description="Using 'Natural Launguage Processing' for the detection of suicide-related posts and user's suicide ideation in cyberspace  and thus helping in sucide prevention."
              ghLink="https://github.com/SHManuel/IA-SDS"
            />
          </Col>

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
